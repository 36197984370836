import React, { Component } from "react";
import { css } from "emotion";

import Steps from "components/Steps";
import { formatPhoneNumber } from "utils/helpers";
import { apiGet, apiPost } from "utils/api";

import Appointment from "./Appointment";
import Client from "./Client";
import Patient from "./Patient";
import NotFound from "./NotFound";
import Confirm from "./Confirm";
import ChoosePets from "./ChoosePets";

const Header = ({ header, org, isEmbed }) => {
  return (
    <div
      className={css`
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);
        padding: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 2rem;
        font-weight: 700;
        background-color: ${org && org.id === 50
          ? "rgb(23, 87, 80)"
          : "#4956b9"};
        color: #fff;
        border-top-left-radius: ${isEmbed ? 0 : "8px"};
        border-top-right-radius: ${isEmbed ? 0 : "8px"};
      `}
    >
      <div>{header}</div>
    </div>
  );
};

const StepHeader = ({ currentStep, steps, org }) => {
  const step = steps.find((s) => s.value === currentStep);

  return (
    <div
      className={css`
        padding: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 1.8rem;
        font-weight: 500;
        background-color: ${org && org.id === 50
          ? "rgb(23, 87, 80)"
          : "#4956b9"};
        color: #fff;
      `}
    >
      <div>
        {step && step.label} {!step && "Step Not Found"}
      </div>
    </div>
  );
};

const APPT_STEPS = [
  { label: "Appointment Type", value: "appt" },
  { label: "Your Details", value: "client" },
  { label: "Your Pet(s)", value: "pet" },
  { label: "Confirm", value: "confirm" },
];
const BOARDING_STEPS = [
  { label: "Visit", value: "visit" },
  { label: "Your Details", value: "client" },
  { label: "Your Pet(s)", value: "pet" },
  { label: "Health Info", value: "health" },
  { label: "Confirm", value: "confirm" },
];

class Booker extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      currentStep: props.form === "boarding" ? "visit" : "appt",
      input: {},
    };
  }

  componentDidMount() {
    apiGet(
      `book_appointment?org=${this.props.org}&locationId=${this.props.locationId}`
    )
      .then(({ data }) => {
        if (
          data &&
          data.preferences &&
          data.preferences.enable_online_booking &&
          data.appointment_types &&
          data.appointment_types.length > 0 &&
          data.locations &&
          data.locations.length > 0
        ) {
          const locationId = parseInt(this.props.locationId || "", 10);

          const location =
            (this.props.locationId &&
              data.locations.find((l) => l.id === locationId)) ||
            data.locations[0];
          const defaultApptType = data.appointment_types.find(
            (a) => a.is_default
          );

          if (data.id === 24 || data.id === 144) {
            window.gtag("config", "UA-59137801-2");
          } else if (data.id === 50) {
            window.gtag("config", "GTM-MRVX47P");
          }

          window.gtag("event", "book_appointment", {
            locationId: location.id,
            event_category: "vetspire_booking",
            event_label: `id:${location.id}`,
          });

          this.setState({
            org: { id: data.id, name: data.name },
            location: location,
            loading: false,
            preferences: data.preferences,
            appointmentTypes: data.appointment_types.sort((a, b) => {
              if (a.is_default && !b.is_default) return -1;
              else if (!a.is_default && b.is_default) return 1;
              else return a.name < b.name;
            }),
            input: {
              appointmentTypeId: defaultApptType ? defaultApptType.id : null,
              locationId: location.id,
              numberOfPets: 1,
              patients: [],
              bookedOnline: this.props.bookedOnline === "false" ? false : true,
            },
          });
        } else {
          this.setState({
            currentStep: "notfound",
            loading: false,
            org: { name: data && data.name },
            errorMessage:
              data &&
              data.preferences &&
              !data.preferences.enable_online_booking ? (
                <span>
                  Online booking is not enabled for this organization. Please
                  turn it on by visiting{" "}
                  <a
                    href={`https://${this.props.org}.vetspire.com/admin/online_book`}
                  >
                    https://{this.props.org}.vetspire.com/admin/online_book
                  </a>
                  .
                </span>
              ) : !data.appointment_types ||
                data.appointment_types.length < 1 ? (
                <span>
                  There is no available appointment type that can be booked
                  online. Configure which appointment types can book online by
                  visiting{" "}
                  <a
                    href={`https://${this.props.org}.vetspire.com/admin/appointment_types`}
                  >
                    https://{this.props.org}
                    .vetspire.com/admin/appointment_types
                  </a>
                  .
                </span>
              ) : (
                "Organization not found."
              ),
          });
        }
      })
      .catch(() => {
        this.setState({ currentStep: "notfound", loading: false });
      });
  }

  onCheckClient(result) {
    if (result.data) {
      const {
        patients,
        id: clientId,
        client_external_comm_preferences: commPreferences,
      } = result.data;

      // update with existing patients if they exist
      if (patients && patients.length > 0) {
        this.setState({
          input: { ...this.state.input, clientId },
          currentStep: "pet",
          addNewPet: false,
          patients,
        });
      } else
        this.setState({
          currentStep: "pet",
          addNewPet: true,
        });

      // check opt in preferences
      if (
        this.state.preferences &&
        this.state.preferences["org.enable_comm_opt_in"]
      ) {
        const userOptInPreferences = commPreferences ? commPreferences : {};
        this.setState({ input: { ...this.state.input, userOptInPreferences } });
      }
    } else if (result.resp === "not found") {
      this.setState({ currentStep: "pet", addNewPet: true });
    } else if (result.resp === "Multiple clients match the submitted params") {
      alert(
        [
          "We are unable to schedule appointments for this account through our online booking",
          "service due to an error in our system. We apologize for the inconvenience. Please",
          "call us",
          this.state.location.phone_number
            ? `at ${formatPhoneNumber(this.state.location.phone_number)}`
            : "",
          "and we will be happy to schedule an appointment for you.",
        ]
          .filter(Boolean)
          .join(" ")
      );
    } else {
      alert("Uh oh! Something went wrong. Please try again.");
    }
  }

  renderCurrentStep(value) {
    const {
      input,
      org,
      location,
      appointmentTypes,
      errorMessage,
      preferences,
    } = this.state;

    switch (value) {
      case "appt":
        return (
          <Appointment
            sameDayOnlyAppointments={location.enable_same_day_appointments_only}
            location={location}
            orgSlug={this.props.org}
            appointmentTypes={appointmentTypes}
            input={input}
            onChange={(updates) => this.updateInput(updates)}
            onNext={() => {
              window.gtag("event", "select_time", {
                locationId: location.id,
                event_category: "vetspire_booking",
                event_label: `id:${location.id}`,
              });
              if (this.props.pgrId && this.props.pgrId !== "") {
                apiGet(
                  `book_appointment/check_client?slug=${this.props.org}&pgrId=${this.props.pgrId}&locationId=${location.id}`
                ).then((result) => {
                  if (result && result.data) {
                    this.updateInput({
                      givenName: result.data.given_name,
                      familyName: result.data.family_name,
                      phoneNumber: result.data.phone_number,
                      email: result.data.email,
                      address: result.data.address,
                    });
                    this.setState({ currentStep: "client" });
                  } else this.setState({ currentStep: "client" });
                });
              } else {
                this.setState({ currentStep: "client" });
              }
            }}
            preferences={preferences}
          />
        );
      case "client":
        return (
          <Client
            input={input}
            org={org}
            onChange={(updates) => {
              let newUpdates = { ...updates };
              this.updateInput(newUpdates);
            }}
            onNext={() => {
              const nPets = input.numberOfPets;
              const patients = [];
              for (let i = 0; i < nPets; i++)
                patients.push({
                  selected: true,
                });
              this.updateInput({ patients });

              window.gtag("event", "client_info", {
                locationId: location.id,
                event_category: "vetspire_booking",
                event_label: `id:${location.id}`,
              });
              const query = new URLSearchParams({
                slug: this.props.org,
                givenName: input.givenName,
                familyName: input.familyName,
                email: input.email,
                phoneNumber: input.phoneNumber
              }).toString();
              apiGet(
                `book_appointment/check_client?${query}`
              ).then((result) => this.onCheckClient(result));
            }}
          />
        );
      case "pet":
        if (this.state.addNewPet) {
          return (
            <Patient
              input={input}
              onChange={(updates) => this.updateInput(updates)}
              onNext={() => {
                window.gtag("event", "patient_info", {
                  locationId: location.id,
                  event_category: "vetspire_booking",
                  event_label: `id:${location.id}`,
                });
                this.setState({ currentStep: "confirm" });
              }}
              isPetco={org.id === 110}
            />
          );
        } else {
          return (
            <ChoosePets
              input={input}
              patients={this.state.patients}
              onNext={(pids) => {
                if (!pids) {
                  this.setState({ currentStep: "pet", addNewPet: true });
                } else {
                  window.gtag("event", "patient_info", {
                    locationId: location.id,
                    event_category: "vetspire_booking",
                    event_label: `id:${location.id}`,
                  });
                  this.setState({
                    input: {
                      ...input,
                      patientIds: pids,
                      numberOfPets: pids.length,
                    },
                    currentStep: "confirm",
                  });
                }
              }}
            />
          );
        }
      case "confirm":
        return (
          <Confirm
            input={input}
            location={location}
            initialUserOptInPreferences={this.state.input.userOptInPreferences}
            orgPreferences={this.state.preferences}
            onBook={({ smsTransactional }) => {
              return this.book({ smsTransactional });
            }}
          />
        );
      default:
        return <NotFound errorMessage={errorMessage} />;
    }
  }

  updateInput(updates) {
    this.setState(({ input }) => ({ input: { ...input, ...updates } }));
  }

  book({ smsTransactional }) {
    const { input, org, location } = this.state;

    if (
      this.state.preferences &&
      this.state.preferences["org.enable_comm_opt_in"]
    ) {
      if (!input.userOptInPreferences) {
        input.userOptInPreferences = { smsTransactional };
      } else {
        input.userOptInPreferences.smsTransactional = smsTransactional;
      }
    }

    this.recordBookingConversion(org, input.numberOfPets, location);

    return apiPost(
      "book_appointment/book",
      {
        input,
      },
      false
    );
  }

  recordBookingConversion(org, numberOfPets, location) {
    window.gtag("event", "confirm", {
      event_category: "vetspire_booking",
      event_label: `id:${location.id}`,
    });
    window.gtag("event", "booked", {
      value: numberOfPets.toString(),
      event_category: "vetspire_booking",
      event_label: `id:${location.id}`,
    });

    if (org && org.id === 84) {
      window.gtag("event", "conversion", {
        send_to: "AW-1065031518/a3FiCOK3-KUBEN6u7PsD",
        event_category: "vetspire_booking",
        event_label: `id:${location.id}`,
      });
      window.kenshoo.trackConversion(
        "3504",
        "2a8cb7ae-70fb-43f2-b9be-a753593a743c",
        {}
      );
    }
  }

  render() {
    const { form, isEmbed } = this.props;
    const { loading, currentStep, org } = this.state;

    if (loading || !org) {
      return false;
    }

    const isBoarding = form === "boarding";
    const steps = isBoarding ? BOARDING_STEPS : APPT_STEPS;

    return (
      <div>
        <Header
          header={isBoarding ? `Book your visit` : `Book your appointment`}
          org={org}
          isEmbed={isEmbed}
        />
        <StepHeader currentStep={currentStep} steps={steps} org={org} />
        <Steps
          currentStep={currentStep}
          steps={steps}
          goBackTo={(step) => this.setState({ currentStep: step })}
          org={org}
        />
        <div
          className={css`
            padding: 23px 30px 10px;
          `}
        >
          {this.renderCurrentStep(currentStep)}
        </div>
        <div
          className={css`
            font-size: 1.45rem;
            padding: 0 15px 15px;
            display: flex;
            align-items: center;
            justify-content: center;
          `}
        >
          <a
            href="https://vetspire.com"
            className={css`
              text-decoration: none;
              color: rgba(60, 60, 60, 0.6);
              display: flex;
              align-items: center;
            `}
          >
            Powered by{" "}
            <img
              height="22px"
              alt="Vetspire logo"
              src="https://storage.googleapis.com/vetspire_public_images/Vetspire-logo.png"
            />
          </a>
        </div>
      </div>
    );
  }
}

export default Booker;
